.textContainer {
  color: white;
  font-size: 2rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  z-index: 2;
  padding-left: 15%;
}

@media (min-width: 1920px) {
  .textContainer {
    padding-left: calc((100% - 1920px) / 2 + 15%);
  }
  .introductionItemContainerContainer {
    padding-right: calc((100% - 1920px) / 2 + 15%);
  }
}

.textContainer > p:nth-child(3) {
  font-size: 3rem;
  margin-bottom: 128px;
}

.introductionContainer {
  height: 100dvh;
  width: 100vw;
  position: sticky;
  view-timeline: --this-screen;
  top: 0;
  display: flex;
  justify-content: space-between;
}

.introductionItemContainer {
  margin-top: 120px;
  height: calc(100% - 120px - 120px);
  width: fit-content;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  padding-right: 24px;
  border-right: 2px solid red;
  z-index: 2;
}

.background {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}

.introductionItemContainer > div {
  width: 320px;
}

@supports (animation-timeline: --this-screen) {
  .background {
    animation-timeline: --this-screen;
    animation-name: fade-in-out;
    animation-timing-function: ease-in;
  }

  .introductionItemContainer > div:nth-child(1) {
    animation-timeline: --entire-view;
    animation-name: slide-from-bottom1;
    animation-duration: 1ms;
    animation-timing-function: ease-out;
    animation-fill-mode: both;
  }

  .introductionItemContainer > div:nth-child(2) {
    animation-timeline: --entire-view;
    animation-name: slide-from-bottom2;
    animation-duration: 1ms;
    animation-timing-function: ease-out;
    animation-fill-mode: both;
  }

  .introductionItemContainer > div:nth-child(3) {
    animation-timeline: --entire-view;
    animation-name: slide-from-bottom3;
    animation-duration: 1ms;
    animation-timing-function: ease-out;
    animation-fill-mode: both;
  }

  @media (max-width: 1024px) {
    .introductionItemContainer > div:nth-child(1) {
      position: relative;
      animation-timeline: --entire-view;
      animation-name: slide-from-right1;
      animation-duration: 1ms;
      animation-timing-function: ease-out;
    }

    .introductionItemContainer > div:nth-child(2) {
      position: absolute;
      animation-timeline: --entire-view;
      animation-name: slide-from-right2;
      animation-duration: 1ms;
      animation-timing-function: ease-out;
    }

    .introductionItemContainer > div:nth-child(3) {
      position: absolute;
      animation-timeline: --entire-view;
      animation-name: slide-from-right3;
      animation-duration: 1ms;
      animation-timing-function: ease-out;
    }
  }
}

@media (max-width: 1920px) {
  .introductionItemContainerContainer {
    padding-right: 15%;
  }
}

@media (max-width: 1440px) {
  .textContainer {
    padding-left: 10%;
  }
  .introductionItemContainerContainer {
    padding-right: 10%;
  }
}

@media (max-width: 1024px) {
  .textContainer {
    padding-left: 5%;
    padding-right: 16px;
    width: auto;
    height: auto;
    padding-top: calc(20% + 100px);
    position: relative;
    justify-content: flex-start;
    z-index: 2;
    flex-grow: 1;
  }

  .introductionItemContainer {
    width: 100%;
    margin: 0 5%;
    height: fit-content;
    flex-direction: row;
    padding-right: 0;
    align-items: center;
    overflow: clip;
  }

  .introductionItemContainerContainer {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-grow: 1;
    padding-bottom: 20%;
  }

  .introductionContainer {
    flex-direction: column;
  }

  .introductionItemContainer > div:nth-child(1) {
    width: 100%;
    position: relative;
  }

  .introductionItemContainer > div:nth-child(2) {
    width: 100%;
    position: absolute;
  }

  .introductionItemContainer > div:nth-child(3) {
    width: 100%;
    position: absolute;
  }
}

@keyframes slide-from-bottom1 {
  25% {
    transform: translateY(calc(100% + 100dvh));
  }
  40% {
    transform: translateY(0);
  }
}

@keyframes slide-from-bottom2 {
  40% {
    transform: translateY(calc(100% + 100dvh));
  }
  50% {
    transform: translateY(0);
  }
}

@keyframes slide-from-bottom3 {
  50% {
    transform: translateY(calc(100% + 100dvh));
  }
  60% {
    transform: translateY(0);
  }
}

@keyframes slide-from-right1 {
  0% {
    transform: translateX(0);
  }
  40% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(calc(-100% - 100dvw));
  }
  100% {
    transform: translateX(calc(-100% - 100dvw));
  }
}

@keyframes slide-from-right2 {
  0% {
    transform: translateX(calc(100% + 100dvw));
  }

  40% {
    transform: translateX(calc(100% + 100dvw));
  }
  50% {
    transform: translateX(0);
  }
  60% {
    transform: translateX(calc(-100% - 100dvw));
  }
  100% {
    transform: translateX(calc(-100% - 100dvw));
  }
}

@keyframes slide-from-right3 {
  0% {
    transform: translateX(calc(100% + 100dvw));
  }

  50% {
    transform: translateX(calc(100% + 100dvw));
  }
  60% {
    transform: translateX(0);
  }
}

@keyframes fade-in-out {
  0% {
    opacity: 0.5;
  }
  25% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

@keyframes slide-from-left {
  0% {
    transform: translateX(calc(-100% - 100dvh));
  }
  25% {
    transform: translateX(0);
  }
}
